import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import http from './utils/request';
import gdp from './utils/gdp-full';
import llgSDK from './utils/liuliangguoSDKpro.min';
// import Vconsole from 'vconsole';
import { encrypt } from './utils/cryptojs-self';
// let vConsole = new Vconsole();
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$encrypt = encrypt;
Vue.prototype.llgSDK = llgSDK;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
